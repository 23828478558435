import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";
import config from "./slicemachine.config.json";

/**
 * The project's Prismic repository name.
 */
export const repositoryName =
  process.env.NEXT_PUBLIC_PRISMIC_ENVIRONMENT || config.repositoryName;

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 *
 * @type {prismic.ClientConfig["routes"]}
 */
const routes = [
  {
    type: "homepage",
    path: "/",
  },
  {
    type: "contact",
    path: "/contact",
  },
  {
    type: "page",
    path: "/:uid",
  },
  {
    type: "teacher_detail",
    path: "/over-ons/docenten/:uid",
  },
  {
    type: "teacher_overview",
    path: "/over-ons/docenten",
  },
  {
    type: "team_detail",
    path: "/over-ons/team/:uid",
  },
  {
    type: "team_overview",
    path: "/over-ons/team",
  },
  {
    type: "book_detail",
    path: "/over-ons/webshop/:uid",
  },
  {
    type: "book_overview",
    path: "/over-ons/webshop",
  },
  {
    type: "about_us_overview",
    path: "/over-ons",
  },
  {
    type: "course_overview",
    path: "/opleidingen",
  },
  // {
  //   type: "course_studyguide",
  //   path: "/opleidingen/:uid/studiegids-aanvragen",
  // },
  // {
  //   type: "course_reviews_form",
  //   path: "/opleidingen/:uid/reviews/formulier",
  // },
  // {
  //   type: "course_reviews",
  //   path: "/opleidingen/:uid/reviews",
  // },
  {
    type: "course_detail",
    path: "/opleidingen/:uid",
  },
  {
    type: "coach_detail",
    path: "/coaches/:uid",
  },
  {
    type: "coach_overview",
    path: "/coaches",
  },
  // {
  //   type: "training_reviews_form",
  //   path: "/trainingen/:uid/reviews/formulier",
  // },
  // {
  //   type: "training_reviews",
  //   path: "/trainingen/:uid/reviews",
  // },
  {
    type: "training_detail",
    path: "/trainingen/:uid",
  },
  {
    type: "training_overview",
    path: "/trainingen",
  },
  // {
  //   type: "event",
  //   path: "/events/:uid",
  // },
  {
    type: "blog_detail",
    path: "/blog/:uid",
  },
  {
    type: "blog_overview",
    path: "/blog",
  },
  {
    type: "agenda_detail",
    path: "/agenda/:uid",
  },
  {
    type: "agenda_overview",
    path: "/agenda",
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param {prismicNext.CreateClientConfig} config - Configuration for the Prismic client.
 */
export const createClient = (config = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
