import "./global.css";
import { AppContext } from "shared/useAppContext";
import { getDocs, getSingle, getDocByUID } from "shared/prismic";
import { initSentry } from "shared/sentry";
import App from "next/app";
import { useEffect } from "react";
import { PrismicPreview } from "@prismicio/next";
import { repositoryName } from "../prismicio.js";

initSentry();

const MyApp = ({
  Component,
  footerNavigation,
  formSettings,
  navigation,
  pageProps,
  pages,
  err,
}) => {
  const contextValue = { navigation, footerNavigation, formSettings, pages };

  useEffect(() => {
    if (typeof window !== "undefined")
      window.Snipcart?.api?.session?.setCurrency("eur");
  }, []);
  return (
    <AppContext.Provider value={contextValue}>
      <Component {...pageProps} err={err} />
      <PrismicPreview repositoryName={repositoryName} />
    </AppContext.Provider>
  );
};

/*
  @TODO: without static import this forces every page to be SSR: https://nextjs.org/docs/advanced-features/custom-app
  Still safe to use since we're exporting the application statically.
  https://github.com/zeit/next.js/discussions/10949
*/
MyApp.getInitialProps = async (appContext) => {
  const pages = await getDocs({ type: "page" });
  const navigation = await getSingle({ type: "navigation" });
  const footerNavigation = await getSingle({
    type: "footer_navigation",
    options: {
      fetchLinks: [
        "about_us_overview.seo_title",
        "agenda_detail.seo_title",
        "agenda_overview.seo_title",
        "book_detail.seo_title",
        "book_overview.seo_title",
        "coach_detail.seo_title",
        "coach_overview.seo_title",
        "course_detail.seo_title",
        "course_overview.seo_title",
        "page.seo_title",
        "team_detail.seo_title",
        "team_overview.seo_title",
        "training_detail.seo_title",
        "training_overview.seo_title",
      ],
    },
  });
  const formSettings = await getSingle({ type: "form-settings" });
  const appProps = await App.getInitialProps(appContext);

  footerNavigation.data.complaints_link = await getDocByUID({
    type: "page",
    uid: "klachtenregeling",
  });

  return {
    ...appProps,
    footerNavigation,
    formSettings,
    navigation,
    pages,
  };
};

export default MyApp;
